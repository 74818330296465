<h1 class="text-2xl my-10 md:text-orange text-center sm:text-left m-auto">Carica i tuoi <b>Buoni Sport</b></h1>

@if(selctedAsd) {
    <div class="my-10">
        <div>            
            <h3 class="text-lg text-center md:text-left">Carica i buoni per l'ASD/SSD</h3>
            <h3 class="text-lg text-center md:text-left font-bold">{{selctedAsd.clubName}}</h3>
            <p class="text-center md:text-left">{{selctedAsd.municipality }}</p>
            <p class="text-center">
                @if(!isLoggedIn) {
                    <p class="text-center">
                        <a class="link inline-block my-5 md:my-10 hover:underline" [routerLink]="['../ricerca-asd']" routerLinkActive="router-link-active">
                            Cambia associazione
                        </a>
                    </p>
                } 
            </p>
        </div>
        <div></div>
    </div>
    <div class="my-10 flex flex-col justify-items-center m-auto">
        @if(platform() !== 'web') {
            <div>
                <a (click)="scan()" class="md:hidden justify-self-center text-center btn rounded-xl w-full md:w-1/2 col-span-6 bg-orange shadow-lg text-white font-bold hover:bg-light-orange"> 
                    Scansiona un codice                
                    <svg width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0 0H7.27273V2.88889H2.90909V7.22222H0V0ZM24.7273 0H32V7.22222H29.0909V2.88889H24.7273V0ZM29.0909 23.1111V18.7778H32V26H24.7273V23.1111H29.0909ZM2.90909 18.7778V23.1111H7.27273V26H0V18.7778H2.90909ZM8.72727 4.33333H10.1818V21.6667H8.72727V4.33333ZM4.36364 4.33333H7.27273V21.6667H4.36364V4.33333ZM13.0909 4.33333H16V21.6667H13.0909V4.33333ZM23.2727 4.33333H24.7273V21.6667H23.2727V4.33333ZM26.1818 4.33333H27.6364V21.6667H26.1818V4.33333ZM17.4545 4.33333H21.8182V21.6667H17.4545V4.33333Z" fill="white"/>
                    </svg>
                </a>
                <p class="md:hidden text-center text-sm my-5">Oppure</p>
            </div>
        }
        
        <div class="text-center">
            <form [formGroup]="form">
                <div class="mb-10 mt-5 w-full md:w-1/2 md:mx-auto">
                    <input
                        class="input form-control w-full md:pl-8 md:mr-3 rounded-xl shadow-lg focus:border-orange focus:outline-none focus:ring-0 focus:bg-input-box-active   pt-2"
                        type="text"
                        placeholder="Inserisci il codice Buono Sport manualmente"
                        name="code"
                        formControlName="code"
                        value=""/>
                </div>
                <button
                    type="submit"
                    (click)="submit()"
                    class="btn rounded-xl w-full md:w-1/2 border-2 bg-orange shadow-lg text-white text-center font-light md:text-lg border-orange no-underline hover:border-light-orange hover:bg-light-orange">
                    Invia Buono Sport
              </button>
            </form>
        </div>
        @if(platform() === 'web') {
            <p  class="text-center mt-9">
                Per scansionare con la fotocamera il codice a barre e caricare più velocemente i tuoi Buoni Sport, scarica l’app Sport Conad
            </p>
            <div class="text-center mt-5">
                <a href="https://apps.apple.com/app/id6504358822" target="_blank" class="m-4">
                    <img class="p-1 inline-block" src="./assets/ios.svg" alt="icona apple store" style="border: 1px solid #e7e7e7; border-radius: 10%;" />
                </a>
                <a href="https://play.google.com/store/apps/details?id=it.conad.sport" target="_blank" class="m-4">
                    <img class="p-1 inline-block" src="./assets/android.svg" alt="icona paly store" style="border: 1px solid #e7e7e7; border-radius: 10%;" />
                </a>
              </div>
        }
    </div>
}