import { Component, OnInit, Output, computed, inject, signal } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import { FormBuilder, ReactiveFormsModule, Validators } from '@angular/forms';
import { CapacitorBarcodeScanner, CapacitorBarcodeScannerCameraDirection, CapacitorBarcodeScannerScanOrientation, CapacitorBarcodeScannerTypeHint } from '@capacitor/barcode-scanner';
import { Dialog } from '@capacitor/dialog';
import { AuthenticationService } from '../../services/authentication.service';
import { PointsService } from '../services/points.service';
import { catchError, tap } from 'rxjs';
import { AsdService } from '../../asd/service/asd.service';
import { JsonPipe } from '@angular/common';
import { LoggedUser } from '../../models/user.model';
import { AppService } from '../../services/app.service';
 

@Component({
  selector: 'app-add-points',
  standalone: true,
  imports: [
    RouterLink,
    ReactiveFormsModule,
    JsonPipe
  ],
  templateUrl: './add-points.component.html',
  styleUrl: './add-points.component.scss'
})

export default class AddPointsComponent implements OnInit {
  @Output() updatedPoints: string = '';
  router = inject(Router);
  fb = inject(FormBuilder);
  authService = inject(AuthenticationService);
  pointService = inject(PointsService);
  asdService = inject(AsdService);
  isLoggedIn: boolean = false;
  user = signal<LoggedUser| null>(null);
  codes: Array<string> = [];
  appService = inject(AppService);
  platform = computed(() => this.appService.platform());

  form = this.fb.group({
    code: ['', Validators.required]
  })
  selctedAsd: any;

async alert (message: string) {
  await Dialog.alert({
    title: 'Attenzione!',
    message: message,
  });
}

  async ngOnInit(): Promise<void> {
    this.isLoggedIn = await this.authService.isAuthenticated();
    await this.authService.getUser().then(loggedUserRes => this.user = loggedUserRes);
    const { value: representativeEditionId } = (await Preferences.get({ key: 'asdId' }));
    if(!representativeEditionId) {
      this.router.navigate(['../ricerca-asd']);
    }
    if(representativeEditionId)
    this.getAsd(representativeEditionId);

  }
  async scan() {
    this.codes = [];
    const scanner = await CapacitorBarcodeScanner.scanBarcode({
      hint: CapacitorBarcodeScannerTypeHint.EAN_13, 
      cameraDirection: CapacitorBarcodeScannerCameraDirection.BACK, 
      scanOrientation: CapacitorBarcodeScannerScanOrientation.PORTRAIT, 
      web: { showCameraSelection: true }
    });
    this.codes.push(scanner.ScanResult);
    this.sendCodes(this.codes);
  }

  async submit() {
    if(this.form.valid && this.form.value) {
      const splittedCodes = (this.form.value.code)?.trim().split(',');
      if(splittedCodes) {
        this.codes = [];
        for(const code of splittedCodes) {
          if(code.length !== 13) {
            this.alert('Il Buono Sport caricato non ha un formato valido');
            return;
          }
          this.codes.push(code)
        }
      }
      this.sendCodes(this.codes);

    }
  }
  async sendCodes(codes: Array<string>) {    
    const code = codes[0];
    this.form.controls.code.setValue('');
    const representativeEditionId = (await Preferences.get({ key: 'asdId'}).then()).value;
    this.pointService.addPoints(representativeEditionId, codes).pipe(
      tap(async (res) => {
        if(!res) return;
        const isValid = res.data.total === res.data.valid ? ' è valido' : 'non è valido';
        await Dialog.alert({
          title: 'Ok',
          message: 'Buono Sport ' + code + ' caricato. ' + 'Il Buono Sport ' + isValid
        });
      }),
      catchError(async error => {
        await Dialog.alert({
          title: 'Attenzione',
          message: error.error.error.message
        });
        return error;
      })
    ).subscribe();
    
    if(representativeEditionId)
      setTimeout(() => {
        this.getAsd(representativeEditionId)
    }, 500)
      
  }

  getAsd(representativeEditionId: string) {
    this.asdService.getAsd(representativeEditionId).pipe(
      tap(async res => {
        if(!res) return;
        this.selctedAsd = res.data[0];
        if(res.data[0].points !== undefined) {
          this.asdService.changePoints(res.data[0].points);
        }
        
      }),
      catchError(error => {
        Dialog.alert({
          title: 'Attenzione',
          message: error.error.error.message
        })
        return error
      })).subscribe();
  }
}