import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { environment as env } from '../../../environments/environment';
import { Observable } from 'rxjs';
import { AddPointsResponse } from '../models/point.model';

@Injectable({
  providedIn: 'root'
})
export class PointsService {
  http = inject(HttpClient);

  addPoints(representativeEditionId: any, codes:Array<string>): Observable<AddPointsResponse> {
    const id = parseInt(representativeEditionId);
    return this.http.post<AddPointsResponse>(`${env.apiUrl}/code`, {'representativeEditionId': id, codes})
  }
}
